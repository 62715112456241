<template>
    <div class="container-fluid p-4">
        <div class="row row-cols-1 mb-4">
            <div class="col">
                <layout-box></layout-box>
            </div>
        </div>

        <div class="row row-cols-2 mb-4">
            <div class="col">
                <layout-box></layout-box>
            </div>
            <div class="col">
                <layout-box></layout-box>
            </div>
        </div>

        <div class="row row-cols-3  mb-4">
            <div class="col">
                <layout-box></layout-box>
            </div>
            <div class="col">
                <layout-box></layout-box>
            </div>
            <div class="col">
                <layout-box></layout-box>
            </div>
        </div>

        <div class="row  mb-4">
            <div class="col-8">
                <layout-box></layout-box>
            </div>
            <div class="col-4">
                <layout-box></layout-box>
            </div>
        </div>

        <div class="row  mb-4">
            <div class="col-4">
                <layout-box></layout-box>
            </div>
            <div class="col-8">
                <layout-box></layout-box>
            </div>
        </div>

        <div class="row row-cols-4">
            <div class="col">
                <layout-box></layout-box>
            </div>
            <div class="col">
                <layout-box></layout-box>
            </div>
            <div class="col">
                <layout-box></layout-box>
            </div>
            <div class="col">
                <layout-box></layout-box>
            </div>
        </div>

    </div>
</template>

<script>

    import LayoutBox from "../../../src/components/LayoutBox";

    export default {
        name: 'Layouts',
        components: {
            LayoutBox
        }
    }
</script>
